$font-open-sans: 'Open Sans', sans-serif
$font-courgette: 'Courgette', sans-serif

$font-headlines: $font-courgette
$font-navi: $font-courgette

$col-body-bg: #000
$col-brown: #CF8B40
$col-grey: #B2B2B2

$z-overlay: 500