@media screen

  $col-footer: #645753

  .haupt-footer
    text-align: center
    font-size: 14px
    color: $col-footer
    +padding(2rem null)
    .moduletable
      display: inline-block
    ul.nav
      > li
        display: inline-block
        border-right: 1px solid $col-footer
        a
          color: $col-footer
          padding: 0 3px
          &:focus,
          &:active,
          &:hover
            color: $col-grey
            background: none
        &:active
          a
            background: none
            &:focus,
            &:active,
            &:hover
              background: none
        &:last-child
          border-right: none
        &:first-child
          border-left: 1px solid $col-footer


  +xs
    +sticky-footer(84px, 84px, 0)
  +sm
    +sticky-footer(84px, 84px, 0)
  +lg
    +sticky-footer(90px, 90px, 0)