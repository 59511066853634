@media screen
  
  .kontakt-seite
    max-width: 500px
    +margin(null auto)

    .page-header
      margin-bottom: 0

  .creative_form_18 .creativecontactform_title
    font-family: $font-courgette !important
    text-shadow: none !important

  .creativecontactform_pre_text.ccf_font_effect_none,
  .creative_form_18 .creativecontactform_field_name
    text-shadow: none !important

  body .creative_form_18 a, .creative_form_18 .ccf_popup_link, .ccf_popup_link_18
    color: $col-brown !important
    border: none !important
    font-weight: normal !important
    text-shadow: none !important

  body .creative_form_18 a:hover, .creative_form_18 .ccf_popup_link:hover, .ccf_popup_link_18:hover
    color: #ccc !important
    border: none !important
    font-weight: normal !important
    text-decoration: underline !important
    text-shadow: none !important
