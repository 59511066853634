@media screen

  /*- Allgemein -----------------------------------------------------------------------*/
  .haupt-navi-nav-rahmen

    font-family: $font-navi

    > ul

      > li

        a
          &:focus,
          &:active,
          &:hover
            background: none

        &.active,
          a
            background: none
            &:focus,
            &:active,
            &:hover
              background: none
              border-color: transparent

  /*- Desktop -----------------------------------------------------------------------*/
  +sm

    .haupt-navi-nav-rahmen
      text-align: center
      font-size: 1.05rem
      +md
        font-size: 1.4rem
      +lg
        font-size: 1.6rem

      > ul

        > li

          float: none
          display: inline-block

          a
            +padding(5px 10px)
            +md
              +padding(null 15px)
            border-bottom: 1px solid transparent
            border-radius: 0
            &:focus,
            &:active,
            &:hover
              color: darken($col-brown, 20%)
              border-color: darken($col-brown, 20%)


  /*- Mobile -----------------------------------------------------------------------*/
  +xs
    .swipe-container
      display: none

    .haupt-navi-rahmen-mobile
      text-align: center
      font-size: 20px
      color: darken($col-brown, 20%)
      .btn-navi-menu
        border-color: darken($col-brown, 20%)
        position: relative
        top: -6px
        transform: scale(1.3)

    .haupt-navi-rahmen-inner
      background: darken($col-brown, 40%)

      .btn-navi-close
        color: $col-brown

    .haupt-navi-nav-rahmen
      +padding(null 15px)
      > ul

        font-size: 1.4rem

        > li

          display: block
          float: none
          border-bottom: 1px dotted $col-brown

          a
            display: block



