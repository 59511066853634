@media screen

  .trx-galerie-col
    margin-bottom: 50px
    +xxs
      width: 100%

  .trx-galerie-item
    position: relative
    height: 0
    padding-bottom: 100%

  .trx-galerie-item-rahmen
    background: #130F0B
    display: block
    border: 1px solid #2A2A2A
    border-radius: 2px
    +position(absolute, 0 null null 0)
    +size(100%)

    img
      +position(absolute, 50% null null 50%)
      transform: translate(-50%, -50%)
      max-width: 55%
      max-height: 55%

  .trx-galerie-item-title
    +position(absolute, null null 0 0)
    width: 100%
    color: darken($col-grey, 10%)
    font-size: 13px
    +xs
      font-size: 14px

    > div
      +position(absolute, 4px null null 50%)
      transform: translateX(-50%)
      width: 100%



