@media screen

  $time-transition: 0.3s

  .haupt-navi-nav-rahmen

    /*- Allgemein -----------------------------------------------------------------------*/
    ul
      list-style: none
      padding: 0

    // erste Ebene
    > ul
      z-index: 1000

  /*- Desktop -----------------------------------------------------------------------*/
  +sm

    .haupt-navi-footer
      display: none

    .haupt-navi-nav-rahmen

      // ul erste Ebene
      > ul
        position: relative

        // li allgemein
        li
          a
            transition: all $time-transition
            text-decoration: none
            display: block

        // li erste Ebene
        > li
          position: relative
          float: left

          // zweite Ebene
          ul
            visibility: hidden
            position: absolute
            margin-top: 30px
            top: 100%

    // Mobile ausblenden
    .haupt-navi-rahmen-mobile,
    .btn-navi-menu,
    .btn-navi-close
      display: none

  /*- Mobile -----------------------------------------------------------------------*/
  +xs

    // Menu Button
    .haupt-navi-rahmen-mobile
      padding: 5px 15px
    .btn-navi-menu
      border: 1px solid #2b2b2b
      display: inline-block
      border-radius: 4px
      padding: 6px 10px 1px 10px
      cursor: pointer
      .glyphicon
        transform: scaleX(1.4)
        +font-size-rem(22)

    // Close Button
    .btn-navi-close
      position: absolute
      top: 5px
      right: 10px
      +font-size-rem(22)
      padding: 6px 10px 1px 10px
      cursor: pointer

    // Swipe Container für jQueryMobile
    .swipe-container
      position: fixed
      top: 0
      left: 0
      width: 10px
      height: 100%
      z-index: 1010

    // Mobile Navigation Rahmen
    .haupt-navi-rahmen-inner
      position: fixed
      top: 0
      //left: 0
      left: -100%
      width: 90%
      height: 100%
      z-index: 1020
      overflow-y: auto
      padding-bottom: 50px
      transition: left $time-transition
      &.open
        left: 0

    // Mobile Navigation
    .haupt-navi-nav-rahmen

      // ul erste Ebene
      > ul
        padding-top: 45px

        // li alle
        li
          a
            display: block

        // li erste Ebene
        > li
          position: relative
          a
            position: relative
            z-index: 1025

          // Icon für Dropdown
          // - Größe eventuell Überschreiben
          ul
            &:before
              z-index: 1021
              position: absolute
              content: '\e080'
              display: inline-block
              font-family: 'Glyphicons Halflings'
              font-weight: normal
              top: 6px
              right: 5px
              font-size: 20px
              transition: all $time-transition

          &.open
            ul
              &:before
                transform: rotate(90deg)

    // Submenu Animation aktiviert
    .haupt-navi-rahmen-inner[data-mobile-navigation="1"]
      .haupt-navi-nav-rahmen
        > ul
          > li
            .submenu-rahmen
              height: 0
              overflow: hidden

    // Footer Default
    .haupt-navi-footer
      position: fixed
      bottom: 0
      left: -100%
      width: calc(90% - 7px)
      background: rgba(0,0,0,0.5)
      transition: all $time-transition
      z-index: 1030
    .haupt-navi-rahmen-inner.open
      .haupt-navi-footer
        left: 0





