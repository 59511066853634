@import "bourbon/bourbon"


@font-face
  font-family: 'Open Sans'
  font-style: normal
  font-weight: 300
  src: url('../fonts/open-sans-v15-latin-300.eot')
  src: local('Open Sans Light'), local('OpenSans-Light'), url('../fonts/open-sans-v15-latin-300.eot?#iefix') format('embedded-opentype'),  url('../fonts/open-sans-v15-latin-300.woff2') format('woff2'),  url('../fonts/open-sans-v15-latin-300.woff') format('woff'),  url('../fonts/open-sans-v15-latin-300.ttf') format('truetype'),  url('../fonts/open-sans-v15-latin-300.svg#OpenSans') format('svg')

@font-face
  font-family: 'Open Sans'
  font-style: normal
  font-weight: 400
  src: url('../fonts/open-sans-v15-latin-regular.eot')
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url('../fonts/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/open-sans-v15-latin-regular.woff2') format('woff2'),  url('../fonts/open-sans-v15-latin-regular.woff') format('woff'),  url('../fonts/open-sans-v15-latin-regular.ttf') format('truetype'),  url('../fonts/open-sans-v15-latin-regular.svg#OpenSans') format('svg')

@font-face
  font-family: 'Open Sans'
  font-style: italic
  font-weight: 400
  src: url('../fonts/open-sans-v15-latin-italic.eot')
  src: local('Open Sans Italic'), local('OpenSans-Italic'), url('../fonts/open-sans-v15-latin-italic.eot?#iefix') format('embedded-opentype'),  url('../fonts/open-sans-v15-latin-italic.woff2') format('woff2'),  url('../fonts/open-sans-v15-latin-italic.woff') format('woff'),  url('../fonts/open-sans-v15-latin-italic.ttf') format('truetype'),  url('../fonts/open-sans-v15-latin-italic.svg#OpenSans') format('svg')

@font-face
  font-family: 'Open Sans'
  font-style: normal
  font-weight: 700
  src: url('../fonts/open-sans-v15-latin-700.eot')
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url('../fonts/open-sans-v15-latin-700.eot?#iefix') format('embedded-opentype'),  url('../fonts/open-sans-v15-latin-700.woff2') format('woff2'),  url('../fonts/open-sans-v15-latin-700.woff') format('woff'),  url('../fonts/open-sans-v15-latin-700.ttf') format('truetype'),  url('../fonts/open-sans-v15-latin-700.svg#OpenSans') format('svg')

@font-face
  font-family: 'Courgette'
  font-style: normal
  font-weight: 400
  src: url('../fonts/courgette-v5-latin-regular.eot')
  src: local('Courgette Regular'), local('Courgette-Regular'), url('../fonts/courgette-v5-latin-regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/courgette-v5-latin-regular.woff2') format('woff2'),  url('../fonts/courgette-v5-latin-regular.woff') format('woff'),  url('../fonts/courgette-v5-latin-regular.ttf') format('truetype'),  url('../fonts/courgette-v5-latin-regular.svg#Courgette') format('svg')


@import "mixins"
@import "settings"

@media screen

  html
    font-size: 100%

  html, body
    height: 100%
    width: 100%

  html, body
    height: 100%
    +xxs
      font-size: 80%
    +xs
      font-size: 90%
    +sm
      font-size: 100%
    +lg
      font-size: 110%

  body
    font-family: $font-open-sans
    overflow-x: hidden
    background: $col-body-bg
    color: $col-grey
    +xs
      font-size: 15px
    +sm
      font-size: 16px

  h1, h2, h3, h4, h5, h6
    font-family: $font-headlines

  .font-normal
    h1, h2, h3, h4, h5, h6
      font-family: $font-open-sans



  .smaller
    font-size: 90%

  a
    transition: color 0.3s, background 0.3s
    color: $col-brown
    &:focus,
    &:active,
    &:hover
      color: lighten($col-brown, 10%)

  //+xlg
    .container
      width: 1370px

  /*- Allgemein -----------------------------------------------------------------------*/
  .rel
    position: relative
  .abs
    position: absolute
  .clear
    clear: both

  /*- Overlay -----------------------------------------------------------------------*/
  .overlay
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba(0,0,0,0.8)
    display: none
    z-index: $z-overlay

@import "content"
//@import "accordion"
//@import "acymailing"
@import "header"
@import "website-navigation"
@import "navigation"
//@import "beitrag"
//@import "uebersicht"
//@import "startseite"
//@import "phoca-download"
//@import "suche"
@import "footer"
@import "formular"
@import "galerie"
//@import "sidebar"
//@import "hinweise"
//@import "images"

