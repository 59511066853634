@media screen

  // Klasse für Menü Link als "Seitenklasse"
  .content-small
    max-width: 970px
    +margin(null auto)

  .content-center
    text-align: center

  .page-header
    border: none

  .content
    line-height: 1.6

    p
      margin-bottom: 2rem

  .content-top
    p
      margin-bottom: 0

  /*- Link Btn -----------------------------------------------------------------------*/
  // <p>Wenn ihr eine Anfrage habt, bitte schreibt an: <br /><a class="my-link-btn" href="mailto:eve@eve-holzdrechseln.de">eve@eve-holzdrechseln.de</a> <br />Die Seite ist derzeit noch im Aufbau, schaut trozdem doch einfach mal rein, und viel Spaß!</p>
  .my-link-btn-p
    +padding(1rem null)
  .my-link-btn
    +padding(0.7rem 4rem)
    background: #130F0B
    box-shadow: 0 0 2px #E3E3E3 inset
    border-radius: 8px
    color: $col-grey
    display: inline-block
    +margin(0.75rem null)
    &:focus,
    &:active,
    &:hover
      color: lighten($col-grey, 10%)
      background: darken(#130F0B, 10%)
      text-decoration: none